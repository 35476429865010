<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <div v-else>
    <section>
      <b-card>
        <div
          v-if="usersFullName"
          class="as-header mb-2"
        >
          <h2 class="text-primary text-capitalize">
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: $route.params.id, participantId: $route.params.participantId },
              }"
            >
              {{ usersFullName }}
            </router-link>
          </h2>
          <h5 class="text-muted text-capitalize">
            {{ applicationAlias }}
          </h5>
        </div>
        <validation-observer
          ref="observer"
          v-slot="{ pristine, invalid, handleSubmit }"
        >
          <b-form
            ref="form"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <div
              v-for="(question, index) in mentorQuestions"
              :key="question.id"
              class="mb-1 box-shadow"
            >
              <template v-if="question.type_id === modelQuestionTypes.TEXT">
                <aom-application-single-text-box
                  :question.sync="question"
                  :index="index"
                  :preferred-language-id="user.preferred_language_id"
                />
              </template>
              <template v-if="question.type_id === modelQuestionTypes.CHOICE">
                <aom-application-choice
                  :question.sync="question"
                  :index="index"
                  :preferred-language-id="user.preferred_language_id"
                />
              </template>
              <template v-if="question.type_id === modelQuestionTypes.MATCHING">
                <aom-application-multi-choice-matching
                  :question.sync="question"
                  :index="index"
                  :preferred-language-id="user.preferred_language_id"
                />
              </template>
              <template v-if="question.type_id === modelQuestionTypes.SCALE">
                <aom-application-rating
                  :question.sync="question"
                  :index="index"
                  :preferred-language-id="user.preferred_language_id"
                />
              </template>
            </div>
            <b-row class="mt-3 d-flex align-items-end justify-content-end">
              <b-col
                sm="4"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="4"
                md="4"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </section>
  </div>
</template>
  
<script>
import {
  BRow, 
  BCol, 
  BSpinner, 
  BForm, 
  BButton, 
  BCard
} from 'bootstrap-vue'; 
import { questionTypes as modelQuestionTypes } from '@/models/questionTypes';
import { userRoles } from '@/models';
import { usersService, applicationService } from '@/services';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from 'vuex';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { ValidationObserver } from "vee-validate";
import AomApplicationSingleTextBox from '@aom-core/AomApplicationSingleTextBox.vue';
import AomApplicationChoice from '@aom-core/AomApplicationChoice.vue';
import AomApplicationMultiChoiceMatching from '@aom-core/AomApplicationMultiChoiceMatching.vue';
import AomApplicationRating from '@aom-core/AomApplicationRating.vue';

export default {
  name: 'ChampionEditMentorApplication',
  components: {
    AomSkeletonLoader,
    BForm, 
    BButton,
    BSpinner,
    BRow,
    BCol,
    ValidationObserver, 
    BCard,
    AomApplicationSingleTextBox,
    AomApplicationChoice,
    AomApplicationMultiChoiceMatching,
    AomApplicationRating
  },
  data() {
    return {
      modelQuestionTypes: modelQuestionTypes,
      isLoading: false,
      ownApplication: null, 
      user: {},
      mentorQuestions: [],
      application: {},
      isUpdating: false
    };
  },
  
  computed: {
    ...mapGetters('programs', ['defaultProgram']),
    mentorForm() {
      return this.defaultProgram?.application_set.applications.filter(a => a.roles[0].id === userRoles.MENTOR);
    },
    usersFullName() {
      if (this.user && this.user.full_name) {
        return `${this.user.full_name}`;
      }
      return "";
    },
    applicationAlias() {
      return 'Mentor Application';
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchUser().then(async () => {
        await this.fetchUsersApplicationAnswers();
      });
    } catch (e) {
      console.log(e);
      this.$toast(makeErrorToast('Something went wrong Application and User not loaded.'));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    clearForm() {
      this.$router.push({name: 'champion-program-participant', params: {id:  this.$route.params.id, participantId: this.$route.params.participantId}});
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        const params = {
          programId: this.$route.params.id,
          userId: this.$route.params.participantId,
          formId: this.mentorForm[0].id
        };
        const mappedAns = this.mentorQuestions.map(q => {
          if(q.type_id === modelQuestionTypes.SCALE){
            return q.statements.map(s => s.answers).flat();
          } else {
            return  q.answers;
          }
        }).flat();
        const data = { answers : mappedAns };
        await applicationService.saveOwnApplicationAnswers(params, data);
        this.$toast(makeSuccessToast('Application Update successful.'));
      } catch(e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isUpdating = false;  
      }
    },
    async fetchUsersApplicationAnswers() {
      try {
        const params = {
          programId: this.$route.params.id,
          userId: this.$route.params.participantId,
          formId: this.mentorForm[0].id
        };
        const result = await applicationService.getOwnApplicationAnswers(params);
        const { data } = result;
        this.application = data;
        this.mentorQuestions = data.questions;
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast('Something went wrong Application not fetched.'));
      }
    },
    async fetchUser() {
      const programId = this.$route.params.id;
      const participantId = this.$route.params.participantId;
      try {
        const user = await usersService.getProgramUser(programId, participantId);
        this.user = {...user.data};
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast('Unable to fetch participant.'));
      } 
    }
  }, 
  
};
</script>
<style lang="scss" scoped>
.box-shadow {
  box-shadow:0 4px 24px 0 rgba(34, 41, 47, 0.1)
}
</style>